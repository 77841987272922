<template lang="pug">
.col-start-1.col-end-3.my-4.overflow-y-auto.overflow-x-hidden
  .flex.flex-col.mb-4.w-full
    span.text-annotationColor.text-sm.font-semibold.mt-4.mb-2 Фильтр
    .flex.mb-3.items-center.justify-between
      el-date-picker.mr-6(
        style='width: 15rem',
        type='daterange',
        :placeholder='$t("routesTrips.trip.date.placeholder")',
        range-separator='-',
        value-format='yyyy-MM-dd HH:mm:ss',
        :clearable='false',
        v-model='eventsDateRange',
        :format='dateFormat',
        popper-class='reportsDatepicker'
      )

      span.font-bold.text-darkblue.text-base.mr-2.cursor-pointer.hover_color-dark(
        @click='$emit("handleeventsdate")'
      ) Показать события
  table.w-full.table-fixed
    thead
      tr.border-b.border-reallylightblue
        th.bg-white.top-0.sticky.z-10.w-6
          skif-checkbox.p-2(
            v-model='isAllCheckedEvent',
            :indeterminate='isIndeterminateEvent',
            @change='$emit("checkallevents",$event)'
            style='margin-left: -8px'
          )

        th.bg-white.top-0.sticky.z-10.text-left
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold Событие
        th.bg-white.top-0.sticky.truncate.z-10.text-left.mr-4
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold Дата/Время
        th.bg-white.top-0.sticky.z-10.text-center
          span.text-headerText.text-sm.font-SourceSansPro.leading-2.font-semibold Отклонение,мин
        th.w-6.text-center.top-0.sticky.z-10.bg-white(@click='$emit("create-event")')
          svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-all.ease-in-out.duration-200.transform(
            width='16',
            height='16',
            fill='none',
            xmlns='http://www.w3.org/2000/svg'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z'
            )

    tbody
      tr.cursor-pointer.hover_bg-hoverrow(
        v-for='(event, i) in events',
        :key='i',
        @click='$emit("edit-event",event)'
      )
        td.pr-2.text-darkblue(@click.stop)
          skif-checkbox(
            v-model='event.isInArray',
            @change='$emit("changecheckedevent",{event,$event})'
          )
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-xs {{ event.type.value }}
        td.truncate.text-darkblue
          span.font-bold.leading-10.text-xs.mr-4 {{ event.start_date ? event.start_date.slice(0, -3) : event.datetime.slice(0, -3) }}
        td.truncate.text-darkblue.text-center
          span.font-bold.leading-10.text-xs {{ event.finish_date ? event.finish_date.slice(0, -3) : event.deviation_minutes }}
</template>
<script>
import events from '../mixins/events'

import moment from 'moment'
export default {
  mixins: [events],

  watch: {
    eventsDateRange(val) {
      this.$emit('filter-date', val)
    }
  },
  props: ['unit', 'dateFormat', 'role', 'isExtraNameMissing'],
  data() {
    return {
      eventsDateRange: [
        moment()
          .subtract(1, 'days')
          .format('yyyy-MM-DD') + ' 00:00:00',
        moment(new Date()).format('yyyy-MM-DD') + ' 23:59:00'
      ]
    }
  }
}
</script>
